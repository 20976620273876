<template>
    <div>
        <div class="bewertungen-wrapper">
            <h3>{{ $t('Views.Shop.Bewerten.Headlines[0]') }}</h3>
            <ul class="bewertungen">
                <li v-for="(bewertung, index) in bewertungen" :key="index">
                    <div class="bewertungs-wrapper">
                        <span
                            >{{ bewertung.userName }} |
                            {{
                                new Date(
                                    bewertung.created_at
                                ).toLocaleDateString('de-DE')
                            }}
                            | {{ $t('Views.Shop.Bewerten.Labels[3]') }}: {{ bewertung.playTime }}
                        </span>
                        <hr />
                        <p class="bewertung-text">
                            <div class="stars">
                                <span class="star" v-for="star in bewertung.stars">
                                    <font-awesome-icon icon="star" />
                                </span>
                            </div>
                            {{ bewertung.text }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import StarInput from '@/components/Shop/StarInput'
import { useStore } from 'vuex'
import { useEmitter } from '@/lib/useEmitter'
import { useApi } from '@/lib/useApi'

export default defineComponent({
    components: { StarInput },
    setup() {
        const store = useStore()
        const emitter = useEmitter()
        const bewertungen = ref([])
        onMounted(async () => {
            bewertungen.value = await useApi(`getBewertungen`)
        })

        const userBewertung = ref('')
        const rating = ref(5)
        const playTime = computed( () => {
            const date = new Date(null)
            date.setSeconds(parseInt(store.state.game.currentGame.timePlayed) + parseInt(store.state.game.currentGame.timeAdded))
            const utc = date.toUTCString()
            return utc.substr(utc.indexOf(':') - 2, 8)
        })
        const userName = computed({
            get() {
                return store.state.game.currentGame.displayName
            },
            set(value) {
                store.commit({
                    type: 'updateDisplayName',
                    displayName: value,
                })
            },
        })

        emitter.on('ratingUpdate', (value) => {
            rating.value = value
        })

        return {
            bewertungen,
            userName,
            userBewertung,
            store,
            rating,
            playTime,
        }
    },
})
</script>

<style scoped>
form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
}

.bewertungen {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
form input,
form button {
    margin-top: 10px;
    margin-bottom: 10px;
}

form button {
    border: 1px solid var(--base-color);
    background-color: var(--base-color);
    color: var(--base-white);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    cursor: pointer;
}
form label{
    font-size: 18px;
}
form input {
    position: relative;
    height: 100%;
    padding: 10px;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.562);
    outline: none;
    font-size: 17px;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.flex-row div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.flex-row div.star-input {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.star {
    color: gold;
}

.stars {
    display: inline;
}

.bewertungs-wrapper {
    max-width: 800px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.bewertung-text {
    background: lightgrey;
    padding: 15px;
    margin-top: 0;
}

hr {
    margin-bottom: 0;
}
</style>
